import React from "react";
import styled from "styled-components";

const Embed = styled.div`
  > iframe{
    max-width: 640px; 
    width: 100%; 
    aspect-ratio: 16/9;
  }
`

const IntroVideo = ({ url, title }) => (
  <Embed>
    <iframe src={url} title={title} frameborder="0" allowfullscreen="" loading="lazy"></iframe>
  </Embed>
)

export default IntroVideo;