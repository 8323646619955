import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

const SectionWrap = styled.div`
	position: relative;
	padding-top: ${props=>props.pt};
	padding-bottom: ${props=>props.pb};
	margin-bottom: ${props=>props.mb};

	.container {
		position: relative;
		z-index: 1;
		max-width:  ${props=>props.maxWidth};
		margin-left: auto;
		margin-right: auto;
		width: 100%;

		p {
			a {
				color: #EC1C24;
				font-weight:700;
				&:hover, &:focus{
					text-decoration: underline;
				}
			}
		}
	}

	@media(max-width:768px) {
		margin-bottom: ${props=>props.mmb};
	}
`

SectionWrap.defaultProps = {
	pt: '50px',
	pb: '50px',
	mb: '0px',
	mmb: '0px',
	maxWidth: '1440px',
}

const SectionBg = styled.div`
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.gatsby-image-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
	}
`

const GrayWoodSection = ({ children, pt, pb, mb, mmb, maxWidth}) => (
	<SectionWrap pt={pt} pb={pb} mb={mb} mmb={mmb} maxWidth={maxWidth}>
		<SectionBg>
			<StaticImage
        src="../../images/top_mid.png"
        placeholder="blurred"                          
        alt="hero text banner"
      />
		</SectionBg>
		{children}
	</SectionWrap>
);

export default GrayWoodSection