import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import PrimaryLinkButton from "../../components/Button/PrimaryLinkButton";
import LineArrowRight from "../../components/Icons/LineArrowRight"
import IntroVideo from '../../components/IntroVideo';
import { Section, SectionTitle, SectionDescription, SubTitle, FlexWrap, LeftWrap, RightWrap } from "../../components/Section";


const Feature = styled.div`
	display: flex;

	img {
		width: 105px;
		height: 105px;
	}

	.link-bar {
		height: 2px;
		width: 70px;
		border-top: 2px solid #E43D3C;
		margin-top: 50px;
		margin-right: 20px;
	}

	@media(max-width: 991px) {
		img {
			width: 70px;
			height: 70px;
			margin-right: 15px;
		}
		.link-bar {
			display: none;
		}
	}
`

const SectionBg = styled.div`
	position: absolute;
	bottom: -60px;
	left: 0;
	width: 50%;

	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
`

const SectionButton = styled(Link)`
	position: relative;

	@media(max-width: 991px) {
		position: absolute;
		bottom: -40px;
		left: 50%;
		width: 100%;
		transform: translateX(-50%);
		text-align: center;
	}
`

const WhySection = ({ data }) => (
	<Section bgColor="#F7F5F5" pt="80px" pb="80px" mpb="120px" spb="80px">
		<SectionBg>
			<StaticImage
        src="../../images/why_bg.png"
        placeholder="blurred"
        quality={100}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="About"
      />
		</SectionBg>
		<div className="container">
			<FlexWrap>
				<LeftWrap width="46%">
					<SectionTitle textAlign="left">{data.title}</SectionTitle>
					<SectionDescription textAlign="left">
						<div dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
					</SectionDescription>
					<IntroVideo url={data.videoUrl} title={data.title} />
					{data.buttonUrl && data.buttonText && (
						<SectionButton to={data.buttonUrl}>
							<PrimaryLinkButton text={data.buttonText} icon={<LineArrowRight />} />
						</SectionButton>
					)}
				</LeftWrap>
				<RightWrap width="46%" mmt="50px">
					{data.features.map((feature, i) => (
						<Feature key={`why-feature-${i}`}>
							<img src={feature.icon.file.url} alt={feature.title} />
							<div className="link-bar"></div>
							<div className="text-wrap">
								<SubTitle textAlign="left" fontSize="30px" mt="23px" mb="16px" mpt="0px">
									{feature.title}
								</SubTitle>
								<SectionDescription textAlign="left" mt="0">
									<div dangerouslySetInnerHTML={{__html: feature.description.childMarkdownRemark.html}} />
								</SectionDescription>
							</div>
						</Feature>
					))}
				</RightWrap>
			</FlexWrap>
		</div>
	</Section>
);

export default WhySection