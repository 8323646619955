import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../layouts';
import Seo from '../components/seo';
import GrayWoodSection from '../components/Section/GrayWoodSection';
import QuoteForm from '../components/QuotePopUp/QuoteForm';
import {
  BreadCrumb,
  Section,
  SectionLead,
  SectionPageTitle,
  SectionTitle,
  SectionDescription,
  MarkdownContent,
  FlexWrap,
  LeftWrap,
  RightWrap,
} from '../components/Section';
import WhySection from '../sections/Home/WhySection';
import CtaSection from '../sections/CtaSection';
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton';
import SecondButton from '../components/Button/SecondButton';
import LineArrowRight from '../components/Icons/LineArrowRight';
import PhoneIcon from '../components/Icons/PhoneIcon';

const HeroTitle = styled(SectionPageTitle)`
	max-width: 650px;
`

const HeroFeatures = styled.div`
	display: flex;
	width: fit-content;
	margin: 0 auto;
	align-items: center;

	.item {
		padding: 5px 24px;
		border-right: 1px solid #000000;
		font-size: 30px;
		line-height: 45px;
		font-weight: 600;
		color: #000000;
		text-align: center;

		&:last-child {
			border-right: 0;
		}
	}

	@media(max-width: 1300px) {
		flex-wrap: wrap;
		justify-content: center;
		max-width: 750px;

		.item {
			&:nth-child(2) {
				border-right: 0;
			}
		}
	}
	@media(max-width: 768px) {
		.item {
			width: 100%;
			max-width: 480px;
			margin: 0 auto 15px;
			padding-bottom: 15px;
			border-right: 0;
			border-bottom: 1px solid #000000;
			font-size: 24px;
			line-height: 36px;
			padding-left: 0;
			padding-right: 0;
			text-align: center;
		}
	}
`

const HelpInfoTitleList = styled.div`
  margin-bottom: 20px;

  li {
    font-size: 20px;
    font-weight: 600;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`

const HelpInfoWrap = styled.div`
  margin-bottom: 20px;
  width: 100%;

  h2 {
    margin-bottom: 30px;
  }
`

const FormWrap = styled(RightWrap)`
  background: #381F1F;
  color: #fff;
  margin: 20px 0;

  h2 {
    color: #fff;
  }
  p {
    color: #fff;
  }

  input {
    color: #fff;
    background: #381F1F;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, .1);
  }
  textarea {
    color: #fff;
    background: #381F1F;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, .1);
  }
`

const AssistanceWrap = styled(LeftWrap)`
	border-right: 1px solid #ffffff;

	h2 {
		max-width: 100%;
		width: 100%;
		margin: 20px 0 30px;

		@media(min-width: 991px) {
			max-width: 500px;
		}
	}
	@media(max-width: 991px) {
		margin-bottom: 40px;
	}
`

const HelpAndInfoPage = ({ data, location}) => {
	const pageData = data.contentfulHelpAndInfoPage
  const scrollToHelp = (i) => {
    const elementId = "help-" + i

    if(typeof window !== "undefined") {
      document.getElementById(elementId).scrollIntoView({behavior: "smooth"});
    }
  }
	return (
		<Layout footerCta location={location}>
			<Seo title={pageData.metaTitle} description={pageData.metaDescription} />
			<BreadCrumb position="relative">
				<div className="container">
					<Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
					<span>{pageData.pageName}</span>
				</div>
			</BreadCrumb>
			<GrayWoodSection>
					<div className="container">
						<HeroTitle>
							{pageData.heroTitle}
						</HeroTitle>
            <FlexWrap alignItems="center">
              <LeftWrap>
                <HelpInfoTitleList>
                  {
                    pageData.helpAndInfos.map((item, i) => <li key={i} onClick={() => scrollToHelp(i)} aria-hidden="true">{item.title}</li>)
                  }
                </HelpInfoTitleList>
                <SectionDescription maxWidth="950px" textAlign="left">
                  <div dangerouslySetInnerHTML={{__html: pageData.heroDescription.childMarkdownRemark.html}} />
                </SectionDescription>
              </LeftWrap>
              <FormWrap>
                <QuoteForm background="#381F1F" location={location} />
              </FormWrap>
            </FlexWrap>
						<HeroFeatures>
						{
							pageData.heroFeatures.map((item, i) => (
								<div className="item" key={i}>
									{item.content}
								</div>
							))
						}
						</HeroFeatures>
					</div>
			</GrayWoodSection>
			<Section bgColor="#381F1F" pt="100px" pb="100px" spt="42px" spb="31px">
				<div className="container">
					<FlexWrap alignItems="center">
						<AssistanceWrap>
							<SectionLead textAlign="left" color="#ffffff" mb="10px">
								Get your dream building installed at your site
							</SectionLead>
							<SectionTitle textAlign="left" color="#ffffff" >
								Call today to talk to our building experts
							</SectionTitle>
							<a href="tel:3867556499">
								<SecondButton text="(386) 755-6449" icon={<PhoneIcon fill="#ffffff" />} />
							</a>
						</AssistanceWrap>
						<RightWrap>
							{pageData.videoSection.label && 
								<SectionLead textAlign="left" color="#E43D3C" mb="10px">
									{pageData.videoSection.label}
								</SectionLead>
							}
							<SectionTitle textAlign="left" color="#ffffff">{pageData.videoSection.title}</SectionTitle>
							<SectionDescription  textAlign="left" color="#ffffff">
								<div dangerouslySetInnerHTML={{__html: pageData.videoSection.description.childMarkdownRemark.html}} />
							</SectionDescription>
							{pageData.videoSection.buttonText &&
								<Link to={pageData.videoSection.buttonUrl}>
									<PrimaryLinkButton text={pageData.videoSection.buttonText} icon={<LineArrowRight />} />
								</Link>
							}
						</RightWrap>
					</FlexWrap>
				</div>
			</Section>
			<WhySection data={pageData.whyBamSection} />
			<GrayWoodSection>
				<div className="container">
					<MarkdownContent maxWidth="1024px">
            {
              pageData.helpAndInfos.map((item, i) => (
                <HelpInfoWrap id={`help-${i}`}>
                  <h2>{item.title}</h2>
                  <div dangerouslySetInnerHTML={{__html: item.description.childMarkdownRemark.html}} />
                </HelpInfoWrap>
              ))
            }
					</MarkdownContent>
				</div>
			</GrayWoodSection>
			<CtaSection />
		</Layout>
	)
};

export default HelpAndInfoPage

export const query = graphql`
  query HelpAndInfoPageQuery {
    contentfulHelpAndInfoPage {
      metaTitle
      metaDescription
      pageName
      heroTitle
      heroDescription {
	      childMarkdownRemark {
	        html
	      }
	    }
	    heroFeatures {
	      content
	    }
	    videoSection {
        videoUrl
        label
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        buttonText
        buttonUrl
      }
      whyBamSection {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        videoUrl
        image {
          file {
            url
          }
        }
        buttonUrl
        buttonText
        features {
          icon {
            file {
              url
            }
          }
          title
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      processSection {
	      label
	      title
	      description {
	        childMarkdownRemark {
	          html
	        }
	      }
	      data {
	        feature
	        description
	      }
	    }
      helpAndInfos {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`