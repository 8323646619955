import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import {
	Section,
	SectionBgWrap,
	SectionLead,
	SectionTitle,
	FlexWrap,
	LeftWrap,
	RightWrap,
} from '../components/Section'
import SecondButton from '../components/Button/SecondButton';
import PhoneIcon from '../components/Icons/PhoneIcon';

const BgWrap = styled(SectionBgWrap)`
	width: 100%;
	height: 100%;
	overflow: hidden;
	.gatsby-image-wrapper {
		width: 110%;
		margin-left: -5%;
	}
`

const ButtonWrap = styled(RightWrap)`
	width: 254px;

	.btn-second {
		width: 100%;
		height: 50px;

		.text {
			font-size: 20px;
		}
	}
`

const CtaSection = ({ mb }) => (
	<Section pt="100px" pb="100px" mb={mb? mb: "100px"} smb="20px">
		<BgWrap>
			<StaticImage
        src="../images/cta-bg.png"
        placeholder="blurred"
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="About"
      />
		</BgWrap>
		<div className="container">
			<FlexWrap maxWidth="1024px" alignItems="center">
				<LeftWrap width="calc(100% - 300px)">
					<SectionLead color="#FFFFFF" mb="0" textAlign="left">
						Get your dream building installed at your site
					</SectionLead>
					<SectionTitle color="#FFFFFF" textAlign="left" mb="0">
						Call today to talk to our building experts
					</SectionTitle>
				</LeftWrap>
				<ButtonWrap width="254px" mmt="20px">
					<a href="tel:3867556449" aria-label='contact-number'><SecondButton text="(386) 755-6449" icon={<PhoneIcon fill="#ffffff" />} /></a>
				</ButtonWrap>
			</FlexWrap>
		</div>
	</Section>
);

export default CtaSection;
